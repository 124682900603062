import React, { useReducer } from 'react';
import axios from 'axios';
import SalesContext from './salesContext';
import SalesReducer from './salesReducer';

import {

	CHANGE_ADMINISTRATION_1,
	CHANGE_ADMINISTRATION_1_OPTIONS,
	CHANGE_ADMINISTRATION_1_OPTIONS_SELECTED,
	CHANGE_CONTACT_AGREE_TYPES_FLAG,
	CHANGE_CONTACT_DATE,
	CHANGE_CONTACT_TYPES_FLAG,
	CHANGE_TITLE,
	CHANGE_CUSTOMER_TYPES_FLAG,
	SET_ACTIVE_CONTACT,

	ADMIN1_TS,
 
} from '../types';

const SalesState = props => {

    const initialState = {

		filterContactDate:		'',
		filterFilepath:			'',
		filterNote:				'',
		filterTitle:			'',

		activePage:				1,
		invoices:				[],
		numPages:				1,
		numRows:				20,
		sortingCol:				0,
		sortingDirection:		'asc',

		contactDate:			'',
		title:					'',
		customerTypesFlag:		0,
		plzRanges:				[],
		administration1:		'',
		administration1Options:	[],
		administration1OptionsSelected: [],
		contactFunction:		'',
		lastContactDateFrom:	'',
		lastContactDateTo:		'',
		contactTypesFlag:		0,
		administration23:		'',
		contactAgreeTypesFlag:	0,

		activeContact:			-1,
		displayDetails:			true,
		modalDetailsError:		'',
		modalDetailsTitle:		'Neuer Kontaktpunkt',

    }
    const [state,dispatch] = useReducer(SalesReducer, initialState);


	const addAdministrations1Tag = (id) => {

		const option = {
			id: id,
			value: document.getElementById('list_item-' + id).innerHTML,
		};
		const newSelected = state.administration1OptionsSelected;
		newSelected.push(option);

		dispatch({
			type: CHANGE_ADMINISTRATION_1_OPTIONS_SELECTED,
			payload: newSelected,
		});

		dispatch({
			type: CHANGE_ADMINISTRATION_1,
			payload: '',
		});

		const newOptions = [];
		for(let i=0; i <state.administration1Options.length; i++) {
			if(state.administration1Options[i].id !== id) 
				newOptions.push(state.administration1Options[i]);
		}

		dispatch({
			type: CHANGE_ADMINISTRATION_1_OPTIONS,
			payload: newOptions,
		});
	}

	
	const cancelEditContactPoint = () => {
		
		dispatch({
			type: SET_ACTIVE_CONTACT,
			payload: {
				contactDate: 			'',
				title: 					'',
				customerTypesFlag: 		0,
				plzRanges: 				[],
				administration1: 		'',
				contactFunction: 		'',
				lastContactDateFrom: 	'',
				lastContactDateTo: 		'',
				contactTypesFlag: 		0,
				administration23: 		'',
				contactAgreeTypesFlag: 	0,
				activeContact: 			-1,
				displayDetails: 		false,
			}
		});
	}

	
	const changeAdministration1 = (e) => {

		const needle = e.target.value;
		console.log(needle)
		
		/* HANDLE INPUT FIELD */
		dispatch({
			type: CHANGE_ADMINISTRATION_1,
			payload: needle,
		});

		dispatch({
			type: CHANGE_ADMINISTRATION_1_OPTIONS,
			payload: [],
		});


		const params = new URLSearchParams();
		params.append('jwt', sessionStorage.jwt);
		params.append('needle', needle);

		const axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			}
		};

		const url = process.env.REACT_APP_API_URI + '/sales/get_administrations1.php';

		axios.post(url,params,axiosConfig)
		.then(response => {
			if(response.status === 200) {

				console.log(response.data);

				let i, j, bFound;
				const newOptions = [];
				for(i=0; i<response.data.admins1.length; i++) {

					bFound = false;
					for(j=0; j<state.administration1OptionsSelected.length; j++) {

						if(state.administration1OptionsSelected[j].id === response.data.admins1[i].id) {
							bFound = true;
							break;
						}
					}

					if(bFound === false)
						newOptions.push(response.data.admins1[i]);
				}
				
				dispatch({
					type: CHANGE_ADMINISTRATION_1_OPTIONS,
					payload: newOptions,
					});

			} else if(response.status === 401) {
				window.location.href ='#/logout';
			} else {
				console.log('Anderer Fehler');
			}
		})
		.catch(error => {
			if(axios.isCancel(error)) {
				console.log('Request cancelled')
			} else {

				if(error.response){
					// The server answered with an error
					if(error.response.status === 401) {
						window.location.href = '#/logout';
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if(error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log('Error', error.message);
				}
			}
		})
	}
        
	const changeAdministration23 = (e) => {
		console.log('changeAdministration23')
	}
        
	const changeContactAgreeTypesFlag = (flag) => {
		
		let newTypesFlag = state.contactAgreeTypesFlag;
		switch(flag) {

			/* Telefon */
			case 1:
				switch(state.contactAgreeTypesFlag) {
					case 0:
						newTypesFlag = 1;
						break;

					case 1:
						newTypesFlag = 0;
						break;

					case 2:
						newTypesFlag = 5;
						break;

					case 3:
						newTypesFlag = 6;
						break;

					case 4:
						newTypesFlag = 7;
						break;

					case 5:
						newTypesFlag = 2;
						break;

					case 6:
						newTypesFlag = 3;
						break;

					case 7:
						newTypesFlag = 4;
						break;

					case 8:
						newTypesFlag = 11;
						break;

					case 9:
						newTypesFlag = 12;
						break;

					case 10:
						newTypesFlag = 9;
						break;

					case 11:
						newTypesFlag = 8;
						break;

					case 12:
						newTypesFlag = 9;
						break;

					case 13:
						newTypesFlag = 10;
						break;

					case 14:
						newTypesFlag = 15;
						break;
					
					case 15:
						newTypesFlag = 14;
						break;

					default:
						newTypesFlag = state.contactAgreeTypesFlag;
				}
				break;

			/* E-Mail */
			case 2:
				switch(state.contactAgreeTypesFlag) {
					case 0:
						newTypesFlag = 2;
						break;

					case 1:
						newTypesFlag = 5;
						break;

					case 2:
						newTypesFlag = 0;
						break;

					case 3:
						newTypesFlag = 8;
						break;

					case 4:
						newTypesFlag = 9;
						break;

					case 5:
						newTypesFlag = 1;
						break;

					case 6:
						newTypesFlag = 11;
						break;

					case 7:
						newTypesFlag = 12;
						break;

					case 8:
						newTypesFlag = 3;
						break;

					case 9:
						newTypesFlag = 4;
						break;

					case 10:
						newTypesFlag = 14;
						break;

					case 11:
						newTypesFlag = 6;
						break;

					case 12:
						newTypesFlag = 7;
						break;

					case 13:
						newTypesFlag = 15;
						break;

					case 14:
						newTypesFlag = 10;
						break;

					case 15:
						newTypesFlag = 13;
						break;

					default:
						newTypesFlag = state.contactAgreeTypesFlag;
				}
				break;

			/* Print-Werbung */
			case 3:
				switch(state.contactAgreeTypesFlag) {
					case 0:
						newTypesFlag = 3;
						break;

					case 1:
						newTypesFlag = 6;
						break;

					case 2:
						newTypesFlag = 8;
						break;

					case 3:
						newTypesFlag = 0;
						break;

					case 4:
						newTypesFlag = 10;
						break;

					case 5:
						newTypesFlag = 11;
						break;

					case 6:
						newTypesFlag = 1;
						break;

					case 7:
						newTypesFlag = 13;
						break;

					case 8:
						newTypesFlag = 2;
						break;

					case 9:
						newTypesFlag = 14;
						break;

					case 10:
						newTypesFlag = 4;
						break;

					case 11:
						newTypesFlag = 5;
						break;

					case 12:
						newTypesFlag = 15;
						break;

					case 13:
						newTypesFlag = 7;
						break;

					case 14:
						newTypesFlag = 9;
						break;

					case 15:
						newTypesFlag = 12;
						break;

					default:
						newTypesFlag = state.contactAgreeTypesFlag;
				}
				break;

			/* Print-Newsletter */
			case 4:
				switch(state.contactAgreeTypesFlag) {
					case 0:
						newTypesFlag = 4;
						break;
					case 1:
						newTypesFlag = 7;
						break;
					case 2:
						newTypesFlag = 9;
						break;
					case 3:
						newTypesFlag = 10;
						break;
					case 4:
						newTypesFlag = 0;
						break;
					case 5:
						newTypesFlag = 12;
						break;
					case 6:
						newTypesFlag = 13;
						break;
					case 7:
						newTypesFlag = 1;
						break;
					case 8:
						newTypesFlag = 14;
						break;
					case 9:
						newTypesFlag = 2;
						break;
					case 10:
						newTypesFlag = 3;
						break;
					case 11:
						newTypesFlag = 15;
						break;
					case 12:
						newTypesFlag = 5;
						break;
					case 13:
						newTypesFlag = 6;
						break;
					case 14:
						newTypesFlag = 8;
						break;
					case 15:
						newTypesFlag = 11;
						break;
					default:
						newTypesFlag = state.contactAgreeTypesFlag;
				}
				break;

			default:
				newTypesFlag = state.contactAgreeTypesFlag;
		}

		dispatch({
			type: CHANGE_CONTACT_AGREE_TYPES_FLAG,
			payload: newTypesFlag,
		});
	}
        
	const changeContactDate = (date) => {
		dispatch({
			type: CHANGE_CONTACT_DATE,
			payload: date,
		});
	}

	const changeContactFunction = (e) => {
		console.log('changeContactFunction')
	}

	const changeCustomerTypesFlag = (flag) => {
		
		let newTypesFlag = state.customerTypesFlag;
		switch(flag) {
			
			/* Potenzialkunde */
			case 1:
				switch(state.customerTypesFlag) {
					case 0:
						newTypesFlag = 1;
						break;
					case 1:
						newTypesFlag = 0;
						break;
					case 2:
						newTypesFlag = 3;
						break;
					case 3:
						newTypesFlag = 2;
						break;
					default:
						newTypesFlag = state.customerTypesFlag;
				}
				break;
			
			/* Kunde */
			case 2:
				switch(state.customerTypesFlag) {
					case 0:
						newTypesFlag = 2
						break;
					case 1:
						newTypesFlag = 3;
						break;
					case 2:
						newTypesFlag = 0;
						break;
					case 3:
						newTypesFlag = 1;
						break;
					default:
						newTypesFlag = state.customerTypesFlag;
				}
				break;
		
			default:
				newTypesFlag = state.customerTypesFlag;
		}

		dispatch({
			type: CHANGE_CUSTOMER_TYPES_FLAG,
			payload: newTypesFlag,
		});
	}
        
	const changeLastContactDateFrom = (e) => {
		console.log('changeLastContactDateFrom')
	}
        
	const changeLastContactDateTo = (e) => {
		console.log('changeLastContactDateTo')
	}
        
	const changeContactTypesFlag = (flag) => {
		
		let newTypesFlag = state.contactTypesFlag;
		switch(flag) {
			/* Marketing */
			case 1:
				switch(state.contactTypesFlag) {
					case 0:
						newTypesFlag = 1;
						break;
					case 1:
						newTypesFlag = 0;
						break;
					case 2:
						newTypesFlag = 4;
						break;
					case 3:
						newTypesFlag = 5;
						break;
					case 4:
						newTypesFlag = 2;
						break;
					case 5:
						newTypesFlag = 3;
						break;
					case 6:
						newTypesFlag = 7;
						break;
					case 7:
						newTypesFlag = 6;
						break;
					default:
						newTypesFlag = state.contactTypesFlag;
				}
				break;

			/* Anstoß */
			case 2:
				switch(state.contactTypesFlag) {
					case 0:
						newTypesFlag = 2;
						break;
					case 1:
						newTypesFlag = 4;
						break;
					case 2:
						newTypesFlag = 0;
						break;
					case 3:
						newTypesFlag = 6;
						break;
					case 4:
						newTypesFlag = 1;
						break;
					case 5:
						newTypesFlag = 7;
						break;
					case 6:
						newTypesFlag = 3;
						break;
					case 7:
						newTypesFlag = 5;
						break;
					default:
						newTypesFlag = state.contactTypesFlag;
				}
				break;

			/* Sonstige */
			case 3:
				switch(state.contactTypesFlag) {
					case 0:
						newTypesFlag = 3;
						break;
					case 1:
						newTypesFlag = 5;
						break;
					case 2:
						newTypesFlag = 6;
						break;
					case 3:
						newTypesFlag = 0;
						break;
					case 4:
						newTypesFlag = 7;
						break;
					case 5:
						newTypesFlag = 1;
						break;
					case 6:
						newTypesFlag = 2;
						break;
					case 7:
						newTypesFlag = 4;
						break;
					default:
						newTypesFlag = state.contactTypesFlag;
				}
				break;

			default:
				newTypesFlag = state.contactTypesFlag;

		}
		dispatch({
			type: CHANGE_CONTACT_TYPES_FLAG,
			payload: newTypesFlag,
		});
	}

	const changePlzRanges = (e) => {
		console.log('changePlzRanges')
	}
        
	const changeTitle = (e) => {
		dispatch({
			type: CHANGE_TITLE,
			payload: e.target.value,
		});
	}


	const editContactPoint = (id) => {

		const params = new URLSearchParams();
		params.append('jwt', sessionStorage.jwt);
		params.append('id', id);

		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			}
		};

		let url = process.env.REACT_APP_API_URI + '/sales/get_contact_point.php';

		axios.post(url,params,axiosConfig)
		.then(response => {
			if(response.status === 200) {
				console.log(response.data);
				
				dispatch({
					type: SET_ACTIVE_CONTACT,
					payload: {
						contactDate: 			response.data.contactDate,
						title: 					response.data.title,
						customerTypesFlag: 		response.data.customerTypesFlag,
						plzRanges: 				response.data.plzRanges.split(';*;'),
						administration1: 		response.data.administration1,
						contactFunction: 		response.data.contactFunction,
						lastContactDateFrom:	response.data.lastContactDateFrom,
						lastContactDateTo: 		response.data.lastContactDateTo,
						contactTypesFlag: 		response.data.contactTypesFlag,
						administration23: 		response.data.administration23,
						contactAgreeTypesFlag: 	response.data.contactAgreeTypesFlag,
						activeContact: 			id,
						displayDetails: 		true,
					}
				});

			} else if(response.status === 401) {
				window.location.href ='#/logout';
			} else {
				console.log('Anderer Fehler');
			}
		})
		.catch(function(error) {
			if(error.response){
				// The server answered with an error
				if(error.response.status === 401) {
					window.location.href = '#/logout';
				} else {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			} else if(error.request) {
				//  The request was made but the server didn't respond
				console.log(error.request);
			} else {
				//  Something else went wrong
				console.log('Error', error.message);
			}
		})
	}

	const filterTableByColumns = (id, value) => {

        // switch (id) {

        //     case 0:
        //         setFilterInvoiceMilestoneId(value);
        //         break;

        //     case 1:
        //         setFilterInvoiceNumber(value);
        //         break;

        //     case 2:
        //         setFilterInvoiceDate(value);
        //         break;

        //     case 3:
        //         setFilterInvoiceDueDate(value);
        //         break;

        //     case 4:
        //         setFilterInvoiceAmount(value);
        //         break;

        //     case 5:
        //         setFilterInvoiceComment(value);
        //         break;

        //     case 6:
        //         setFilterInvoicePaid(value);
        //         break;
        // }
    }


	const loadContactPointTemplate = () => {
		console.log('loadContactPointTemplate');
	}


	const newContactPoint = (e) => {
		console.log('newContactPoint')
	}

	const removeAdministrations1Tag = (id, mainId) => {

		const option = {
			id: id,
			value: document.getElementById('tag-' + mainId + '-' + id).innerHTML,
		};

		const newOptions = state.administration1Options;
		newOptions.push(option);

		dispatch({
			type: CHANGE_ADMINISTRATION_1_OPTIONS,
			payload: newOptions,
		});

		const newSelected = [];
		for(let i=0; i < state.administration1OptionsSelected.length; i++) {
			if(state.administration1OptionsSelected[i].id !== id)
				newSelected.push(state.administration1OptionsSelected[i]);
		}

		dispatch({
			type: CHANGE_ADMINISTRATION_1_OPTIONS_SELECTED,
			payload: newSelected,
		});
	}


	const saveContactPointDetails = () => {
		console.log('saveContactPointDetails');
	}

	const setActivePage = (page) => {
		console.log('setActivePage')
	}

	const setDisplayDetails = () => {
		console.log('setDisplayDetails')
	}

	const setModalDetailsError = (err) => {
		console.log('setModalDetailsError')
	}

	const setNumRows = (rows) => {
		console.log('setNumRows')
	}

	const setSortingCol = (colIndex) => {
		console.log('setSortingCol')
	}

	const setSortingDirection = (dir) => {
		console.log('setSortingDirection')
	}

	const sortTable = (id) => {

		if(id === state.sortingCol) {

			if(state.sortingDirection === 'asc') {
				setSortingDirection('desc');
			} else {
				setSortingDirection('asc');
			}

		} else {
			setSortingCol(id);
			setSortingDirection('asc');
		}
	}

	const tableActions = (e) => {

        const action = e.target?.getAttribute('data-method');
        const id = e.target?.getAttribute('data-id');

        switch (action) {
            case 'edit':
                console.log('edit action: ' + id);
                break;
            case 'delete':
                console.log('delete action: ' + id);
                break;
            default:
                return;
        }
    }


    return <SalesContext.Provider value={{

		filterContactDate:	state.filterContactDate,
		filterFilepath: 	state.filterFilepath,
		filterNote: 		state.filterNote,
		filterTitle: 		state.filterTitle,

		activePage:			state.activePage,
		invoices:			state.invoices,
		numPages:			state.numPages,
		numRows:			state.numRows,
		sortingCol:			state.sortingCol,
		sortingDirection:	state.sortingDirection,
		
		contactDate: 			state.contactDate,
		title: 					state.titel,
		customerTypesFlag: 		state.customerTypesFlag,
		plzRanges: 				state.plzRanges,
		administration1: 		state.administration1,
		administration1Options: state.administration1Options,
		administration1OptionsSelected: state.administration1OptionsSelected,
		contactFunction: 		state.contactFunction,
		lastContactDateFrom: 	state.lastContactDateFrom,
		lastContactDateTo: 		state.lastContactDateTo,
		contactTypesFlag: 		state.contactTypesFlag,
		administration23: 		state.administration23,
		contactAgreeTypesFlag:	state.contactAgreeTypesFlag,
		
		displayDetails: 	state.displayDetails,
		modalDetailsError: 	state.modalDetailsError,
		modalDetailsTitle: 	state.modalDetailsTitle,
		
		addAdministrations1Tag,
		cancelEditContactPoint,
		changeContactDate,
        changeTitle,
        changeCustomerTypesFlag,
        changePlzRanges,
        changeAdministration1,
        changeContactFunction,
        changeLastContactDateFrom,
        changeLastContactDateTo,
        changeContactTypesFlag,
        changeAdministration23,
        changeContactAgreeTypesFlag,
		newContactPoint,
		editContactPoint,
		filterTableByColumns,
		loadContactPointTemplate,
		removeAdministrations1Tag,
		saveContactPointDetails,
		setActivePage,
		setDisplayDetails,
		setModalDetailsError,
		setNumRows,
		setSortingCol,
		setSortingDirection,
		sortTable,
		tableActions,

}}>
        {props.children}
    </SalesContext.Provider>
}

export default SalesState;