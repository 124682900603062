import {

	CHANGE_ADMINISTRATION_1,
	CHANGE_ADMINISTRATION_1_OPTIONS,
	CHANGE_ADMINISTRATION_1_OPTIONS_SELECTED,
	CHANGE_CONTACT_AGREE_TYPES_FLAG,
	CHANGE_CONTACT_DATE,
	CHANGE_CONTACT_TYPES_FLAG,
	CHANGE_TITLE,
	CHANGE_CUSTOMER_TYPES_FLAG,
	SET_ACTIVE_CONTACT,

	ADMIN1_TS,

} from '../types';

export default(state, action) => {

    switch(action.type)
    {
		case ADMIN1_TS:
			return {
				...state,
				admin1LastCall: action.payload,
			}
		case CHANGE_ADMINISTRATION_1:
			return {
				...state,
				administration1: action.payload,
				
			};

		case CHANGE_ADMINISTRATION_1_OPTIONS:
			return {
				...state,
				administration1Options: action.payload,
			};

		case CHANGE_ADMINISTRATION_1_OPTIONS_SELECTED:
			return {
				...state,
				administration1OptionsSelected: action.payload,
			}

		case CHANGE_CONTACT_AGREE_TYPES_FLAG:
			return{
				...state,
				contactAgreeTypesFlag: action.payload,
			};

		case CHANGE_CONTACT_DATE:
			return{
				...state,
				contactDate: action.payload,
			};

		case CHANGE_CONTACT_TYPES_FLAG:
			return{
				...state,
				contactTypesFlag: action.payload,
			};

		case CHANGE_TITLE:
			return{
				...state,
				title: action.payload,
			};

		case CHANGE_CUSTOMER_TYPES_FLAG:
			return{
				...state,
				customerTypesFlag: action.payload,
			};

		case SET_ACTIVE_CONTACT:
			return {
				...state,
				contactDate: action.payload.contactDate,
				title: action.payload.title,
				customerTypesFlag: action.payload.customerTypesFlag,
				plzRanges: action.payload.plzRanges,
				administration1: action.payload.administration1,
				contactFunction: action.payload.contactFunction,
				lastContactDateFrom: action.payload.lastContactDateFrom,
				lastContactDateTo: action.payload.lastContactDateTo,
				contactTypesFlag: action.payload.contactTypesFlag,
				administration23: action.payload.administration23,
				contactAgreeTypesFlag: action.payload.contactAgreeTypesFlag,
				activeContact: action.payload.activeContact,
				displayDetails: action.payload.displayDetails,
			}

            
        //  Default Path
        default:
            return state;
    }
}